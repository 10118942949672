// define the routes links here

const ROUTES_LINKS = {
	home: "/",
	overview: "/dashboard",
	auth: "/auth",
	terms_conditions: "/terms&conditions",
	private_policy: "/privacy-policy",
	widget: ":widget"
}

export default ROUTES_LINKS;