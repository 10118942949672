// Terms and Conditions
import "./index.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Components from "../../../components";
import ROUTES_LINKS from "../../../constants/routes";

export default function TermsAndConditions() {
	const [isLoading, setIsLoading] = useState(true);
	const styleRule1 = { marginRight: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt' };
	const styleRule2 = { fontSize: 14, fontFamily: '"Ubuntu",sans-serif', color: '#8E9BAF' };
	const styleRule3 = { fontSize: 14, fontFamily: 'Symbol', color: '#8593A9' };
	const styleRule4 = { marginTop: '0cm', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '0cm', fontSize: 17, fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal' };
	const styleRule5 = { fontSize: 14, fontFamily: '"Ubuntu",sans-serif', color: '#8593A9' };
	const styleRule6 = { fontFamily: '"Ubuntu",sans-serif', color: '#2E66F4' };

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
			console.log("is loading", isLoading);
		}, 2000);
	});


	return (

		<div className="nk-app-root">
			<Helmet>
				<title>Terms and Conditions</title>
				{/* <!-- Landing Page StyleSheet --> */}
				<link rel="stylesheet" href="/assets/css/landingPage/dashlite.css?ver=3.2.3" />
				<link id="skin-default" rel="stylesheet" href="/assets/css/landingPage/theme.css?ver=3.2.3" />
				<link rel="stylesheet" href="/assets/css/skins/theme-red.css" />
			</Helmet>

			{/* main @s */}
			<div className="nk-main">
				<section className="section section-service pb-0" id="service">
					<div className="container">
						<div className="row justify-content-center text-center">
							<div className="col-12">
								<div className="section-head">
									<h2 className="title">Terms and Conditions</h2>
									<p style={styleRule1}>THIS AGREEMENT CONTAINS A BINDING ARBITRATION CLAUSE. PLEASE READ THE AGREEMENT CAREFULLY BEFORE ACCEPTING ITS TERMS AND CONDITIONS. IMPORTANT – PLEASE READ THESE TERMS CAREFULLY BEFORE APPLYING FOR, ACCEPTING, OR USING AN GORILLA BOT ONLINE ACCOUNT OR THE GORILLA BOT NATURAL LANGUAGE PROCESSING SERVICES. BY USING, APPLYING FOR, OR ACCEPTING THE SERVICES OR BY CLICKING ON “I AGREE TO TERMS AND CONDITIONS” BUTTON, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, THAT YOU UNDERSTAND IT, AND THAT YOU AGREE TO BE BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT APPLY FOR, ACCEPT, OR USE AN GORILLA BOT ONLINE ACCOUNT OR THE GORILLA BOT NATURAL LANGUAGE PROCESSING SERVICES.</p>
								</div>
								{/* .section-head */}
							</div>
							{/* .col */}
						</div>
						{/* .row */}
						<div className="section-content">
							<div className="row justify-content-start g-gs">
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#License"}>License</a>
											</h4>
											<p style={styleRule2}>
												1.1 Grant of Licensegorillabot.com. ("Gorilla Bot") grants to you a limited, non-exclusive, non-transferrable and revocable license to GORILLA BOT online voice and natural language processing services as well as any documentation, files, or website information accompanying the services (“Services”) solely to the extent necessary for you to create a natural language interface that operates solely in connection with your devices (“Devices”) and/or software services/applications (“Applications”) Any violation of the terms of this Agreement shall automatically revoke the license granted herein.
											</p>
											<p style={styleRule2}>
												1.2 Limited License ServiceServices include basic services (“Basic Services”) provided free of charge and enhanced services (“Enhanced Services”), which, if available, must be purchased. The licenses granted herein are contingent upon Gorilla Bot’s receipt of the applicable license fees and taxes due for the Enhanced Services. Fees paid for Enhanced Services shall allow you to use Enhanced Services for the duration of the subscription service, as applicable, as selected by you during your registration of the Enhanced Services (“Subscription Period”). The fees owned for the use of the Enhanced Services are set forth on GORILLA BOT’s website and are subject to change upon 30 days’ advance written notice. License fees may vary depending on the usage of the Services. These fees are owed regardless of whether the Services is actually used. No refunds will be given for any unused Services or any early termination of the Subscription Period.
											</p>
											<p style={styleRule2}>
												1.3 Trial, Evaluation, and Beta ServicesIf this Agreement pertains to a trial, beta, or evaluation license then the licenses granted under this Agreement will terminate upon the expiration or cancellation of the trial or evaluation period. You agree to use the Services and any services only for the duration of the trial or evaluation period and only for evaluation or testing purposes. All such licenses are limited to one per customer per promotion or beta test.
											</p>
											<p style={styleRule2}>
												1.4 Restrictions The licenses granted herein are only valid if:
												<ul className="ms-5">
													<li>The Services are NOT modified in any manner</li>
													<li>All copyright and proprietary notices or labels in the Services are maintained in their original format</li>
													<li>The Services are only installed and used in accordance with your network security policies</li>
													<li>You possess the necessary authority and power to install the Services</li>
													<li>You promptly pay all license fees when due</li>
													<li>This Agreement is accepted without any modification</li>
													<li>You have not breached the terms of this Agreement in any manner</li>
												</ul>
											</p>
											<p style={styleRule2}>
												1.5 RegistrationThe Services require registration or activation to be used properly. You agree to provide accurate and complete information on all registration forms. Failure to complete the registration or activation may limit your ability to use the Services or may disable the Services from functioning. When registering or activating Services, you may be required to select a username and password. Maintaining the confidentiality of this password and username is your responsibility, and Gorilla Bot is not responsible for any loss or damage arising from unauthorized access to your account or your failure to comply with this section. You are fully responsible for any activities that occur under your username even if unauthorized. You must notify Gorilla Bot immediately of any unauthorized use of your account. 1.6 Minimum Registration AgeBy registering for GORILLA BOT account, you agree that you are older than 13 years of age.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Services"}>Services</a>
											</h4>
											<p style={styleRule2}>
												2.1 Limited License. The licenses granted herein are only for the purpose of allowing you to connect to and use the Services for your personal or internal business use (including communicating with End Users). You will not use the services to perform natural language processing for any third parties other than End Users.
											</p>
											<p style={styleRule2}>
												2.2 Delivery. Gorilla Bot shall provide you with a web interface and an API protocol based interface necessary to use the Services (“Interface”). You agree to access the Services only through the Interface and will not create any derivative works of the Interface or the Services. You agree not to circumvent any limitations to the Services or Interface as implemented by Gorilla Bot.
											</p>
											<p style={styleRule2}>
												2.3 Storage. When using the Services, Gorilla Bot will save a copy of each record you designate as requiring storage. The saved records will be transferred over the Internet to a server operated by Gorilla Bot or an affiliate or partner of Gorilla Bot. Except as provided in Section 7, Gorilla Bot disclaims all liability for the Services, including any resulting from lost or compromised data. Gorilla Bot does not make any warranty that your data will be stored safely or securely, except that Gorilla Bot warrants that, in performing the Services, it and its affiliates and partners will comply with all applicable data protection legislation and other privacy laws, rules, and regulations when processing and storing personal data. Subject to the foregoing, You give Gorilla Bot permission to transmit all records designated by you as requiring storage to a remote data center operated by Gorilla Bot or an affiliate or partner of Gorilla Bot.com. (“Storage Servers”).
											</p>
											<p style={styleRule2}>
												2.4 Data Loss. Gorilla Bot.com. does not maintain a backup copy of the data stored on our servers and does not guarantee the information against loss or destruction. In the event that this Agreement is terminated, Gorilla Bot may in its sole discretion and without notice to you delete or deny access to the backed up data and such data or records will not be available to you. Gorilla Bot and its affiliates may retain (but shall have no obligation to retain) your data for a period of time after your subscription to the Services expires in order to allow you to renew the Subscription Period if desired.
											</p>
											<p style={styleRule2}>
												2.5 File Deletion. Gorilla Bot does not maintain a copy of the data backed up to our servers and does not guarantee the information against loss or destruction. In the event that the licenses granted herein are revoked, lapse, or terminate, whether by you or by Gorilla Bot.com, including lapse of any trial or evaluation period, Gorilla Bot may, in its sole and absolute discretion, without notice to you, delete or deny access to the backed up data and such data or records will not be available to you. You agree that Gorilla Bot and its affiliates may retain (but shall have no obligation to retain) your data for a period after your trial or license has been terminated, expired, or otherwise lapsed, as part of Gorilla Bot’s marketing to you the opportunity to purchase, renew, or extend a license.
											</p>
											<p style={styleRule2}>
												2.6 Broadcast And Schedule Messages. Gorilla Bot will allow you to send broadcast and schedule messages to active users of the last 7 days. Because of facebook restrictions and conditions of spamming and non promotional messages we have this restriction.
											</p>
											<p style={styleRule2}>
												2.7 End User Data. You acknowledge and agree that you are solely responsible for obtaining all required consents from End Users in connection with any use of your Devices or Applications, which consent shall be compliant with all applicable data protection legislation and other privacy laws, rules, and regulations. Before collecting any End User Data or other information from End Users, you must provide adequate notice of what End User Data and other information you collect and how it will be used and/or shared and obtain any necessary consents. You and your Devices and Applications shall comply comply with all privacy laws and regulations in connection with your access and use of the Services. You will provide and adhere to a privacy policy for your Device or Application that:
												<ul className="ms-5">
													<li>Complies with all applicable laws, rules, and regulations</li>
													<li>Is conspicuously displayed to all End Users</li>
													<li>Clearly and accurately describes to End Users what data and user information you collect (such as personally identifiable information, login information, etc.) and how you use and share such information with Gorilla Bot and third parties.</li>
												</ul>
											</p>
											<p style={styleRule2}>
												2.8 Service Uptime Gorilla Bot doesn't guarantee 100% uptime of the service, our uptime is usually 95%. For dedicated uptime upto 99% please contact support@Gorilla Bot.com for the quotation.											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Ownership"}>Ownership</a>
											</h4>
											<p style={styleRule2}>
												3.1 No Ownership Rights The Services are being licensed, not sold. This Agreement does not grant any ownership rights to you and gives you only a limited license to use the Services during the term of the Agreement. The Services and all related intellectual property rights, whether under copyright, trade secret, patent, or trademark laws, are owned by Gorilla Bot and/or its licensors. Gorilla Bot may pursue all legal remedies for use of the Services in violation of this Agreement. No license is granted herein to resell, create derivative works, reverse engineer, repackage, or modify the Services. Use of the Services within your commercial enterprise for internal purposes is expressly allowed.
											</p>
											<p style={styleRule2}>
												3.2 Copyright The Services contains material that is protected by United States and foreign intellectual property laws, including copyright, trade secret, and patent law. All rights not granted to you herein are expressly reserved by Gorilla Bot. You may not remove any copyright or other proprietary notice of Gorilla Bot from any copy of the Services. 3.2 Copyright The Services contains material that is protected by United States and foreign intellectual property laws, including copyright, trade secret, and patent law. All rights not granted to you herein are expressly reserved by Gorilla Bot. You may not remove any copyright or other proprietary notice of Gorilla Bot from any copy of the Services.
											</p>
											<p style={styleRule2}>
												3.3 Modification Gorilla Bot may modify or discontinue any of its Services or the related Services without notice. Gorilla Bot will provide notice of material changes to the Services or changes to this Agreement by posting such changes at GORILLA BOT web-site which shall be your sole notice of such changes. You agree and acknowledge that you will periodically check the website to inform yourself of any such changes.
											</p>
											<p style={styleRule2}>
												3.4 Submissions In connection with the operation of the Services, Gorilla Bot receives, utilizes, and analyzes communications sent to Gorilla Bot and any of your stored records (“Submissions”). You retain ownership of your Submissions. However, notwithstanding anything to the contrary, you hereby grant Gorilla Bot a license to:
												<ul className="ms-5">
													<li>Use and disclose your Submissions to provide the Services.</li>
													<li>Use your Submissions for Gorilla Bot’s internal business purposes.</li>
													<li>Disclose your Submissions as may be required by law or legal process.</li>
													<li>Otherwise use and disclose your Submissions in accordance with this Agreement.
														Without limiting the foregoing, you agree that Gorilla Bot is expressly and irrevocably authorized to utilize, analyze, modify, reproduce, publish, share, create derivative works of, or otherwise exercise all rights in your Submissions and any analytics, statistics or other data related to or derived from your Submissions and/or your use of the Services for any purpose, provided that such data is in aggregate and anonymized form ("Aggregate Data").Subject to the records licenses, you acknowledge and agree that Gorilla Bot will exclusively own all right, title, and interest in to all Aggregate Data and other anonymous output data generated by the GORILLA BOT Services. Notwithstanding anything to the contrary, if you ever have any ownership interest in any Aggregate Data or anonymous output data, you hereby assign to Gorilla Bot all such right, title, and interest in to such Aggregate Data and anonymous output data, including all intellectual property rights therein. You also hereby grant each user of the Services a non-exclusive license to access your Submissions in accordance with your account settings through the Services, and to use, reproduce, distribute, display and perform such records as permitted through the functionality of the Services and under this Agreement.</li>
												</ul>
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Payment"}>Payment</a>
											</h4>
											<p style={styleRule2}>
												4.1 Fees. The fees for using the Enhanced Services are set forth on the GORILLA BOT website which may be modified by Gorilla Bot; provided that such modified fees shall not be applicable to you unless Gorilla Bot provides you with 30 days’ prior written notice. You agree to pay all fees fully and promptly.
											</p>
											<p style={styleRule2}>
												4.2 Method of Payment. Basic Services are free. License fees for the Enhanced Services must be paid in advance. The credit card information provided to Gorilla Bot or a third party payment processor to pay for the Enhanced Services shall be automatically charged for the Enhanced Services upon renewal. In the event that Gorilla Bot or a third party payment processor is unable to bill the credit card on file or you request that Gorilla Bot not bill the credit card, you shall be solely responsible for any renewal payment required. In the event that payment is not made, the Enhanced Services and services may become unavailable to you and Gorilla Bot may terminate this Agreement without notice to you.
											</p>
											<p style={styleRule2}>
												4.3 Rejected Charges. If any charges are rejected by your credit card issuer then Gorilla Bot may deactivate your account until payment is successfully received. Gorilla Bot may deactivate any account that has a disputed charge until Gorilla Bot, in its sole discretion, determines the dispute resolved.
											</p>
											<p style={styleRule2}>
												4.4 Billing Issues. You must provide Gorilla Bot notice of any billing problems or disputes within sixty (60) days after they first appear on the statement you receive from your bank, credit card company, or other billing company. Failure to notify Gorilla Bot of the problem within the sixty (60) day period will result in your acceptance of the charges and you waive the right to dispute such problems or discrepancies. Failure to use your account will not be deemed a basis for refusing to pay any charges. Gorilla Bot does not provide any refunds for any Services except manifest failure of the Services to operate as intended due to Gorilla Bot’s negligence or willful misconduct.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Restrictions and Representations"}>Restrictions and Representations</a>
											</h4>
											<p style={styleRule2}>5.1 Lawful Use The Services is solely for lawful purposes and use. You are responsible for ensuring that all use of the Services is in accordance with this Agreement. You are solely responsible for ensuring that your use of the Services complies with all applicable laws, statutes, ordinances, regulations, rules and other government authority. The Services is provided subject to this standard commercial agreement and qualifies as commercial computer Services within the meaning of the applicable government acquisition laws. Services shall not be used to display, support, develop, supply or market the physical effects of violence (including, without limitation, blood, gore and organs) on humans or human-like characters, explicit sexual content, sex crimes, disparagement of ethnic or religious groups, racial epithets, profane language or hate speech. </p>
											<p style={styleRule2}>
												5.2 Compliance You agree:
												<ul className="ms-5">
													<li>Not to interfere or disrupt networks connected to Gorilla Bot’s services.</li>
													<li>To comply with all regulations, policies and procedures of networks connected to the services.</li>
													<li>Not to use the services to infringe any third party's copyright, patent, trademark, trade secret or other proprietary rights or any third party’s rights of publicity or privacy.</li>
													<li>Not to post, distribute, or otherwise make available or transmit any computer file that contains a virus, Trojan, adware, or other malware</li>
													<li>Not to attempt to gain unauthorized access to other computer systems.</li>
													<li>Not to create an Application that functions substantially the same as the Services and offer it for use by third parties</li>
													<li>Not to transmit any unlawful, harassing, libelous, defamatory, racist, indecent, abusive, violent, threatening, intimidating, harmful, vulgar, obscene, offensive or otherwise objectionable material of any kind or nature.</li>
												</ul>
											</p>
											<p style={styleRule2}>
												5.3 Export You represent and warranty that you are not located in and will not modify, export or re-export, either directly or indirectly, the Services to any country or entity under United States restrictions or to any country or entity subject to applicable trade sanctions. The United States restricted country and persons list is subject to change without notice from Gorilla Bot, and you must comply with the list as it exists in fact. Gorilla Bot SHALL NOT BE LIABLE FOR YOUR VIOLATION OF ANY SUCH EXPORT OR IMPORT LAWS, WHETHER UNDER UNITED STATES LAW OR FOREIGN LAW.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Disclaimer of Warranties"}>Disclaimer of Warranties</a>
											</h4>
											<p style={styleRule2}>
												6.1 Technical Support Except as otherwise provided herein, Gorilla Bot is under no obligation to provide technical or customer support for the Services. You are solely responsible for properly installing and using the Services. You are responsible for the procurement of any hardware or services required to use the Services, including any computers, servers, or Internet access.
											</p>
											<p style={styleRule2}>
												6.2 Use of Internet. The Services are provided over the Internet. As such, the Services are subject to the operation of the Internet and telecommunications infrastructures as well as the operation of your Internet connection services, all of which are beyond the control of Gorilla Bot. Gorilla Bot does not warrant that the services will be uninterrupted or that you will be able to access or use the Services at the location and times of your choosing.
											</p>
											<p style={styleRule2}>
												6.3 Risk. THE SERVICES IS PROVIDED TO YOU “AS IS” AND “AS AVAILABLE”. ANY USE OF THE S ERVICES IS AT YOUR OWN RISK. THE SOFWARE MAY CONTAIN BUGS, ERRORS, AND OTHER PROBLEMS THAT COULD CAUSE SYSTEM OR OTHER FAILURES AND DATA LOSS. Gorilla Bot DOES NOT WARRANT THE PERFORMANCE OF THE SERVICES, THAT THE SERVICES WILL OPERATE UNINTERRUPTED OR ERROR-FREE, OR THAT THE SERVICES WILL OPERATE IN ACCORDANCE WITH ANY ACCOMPANYING DOCUMENTATION. Gorilla Bot IS NOT LIABLE FOR ANY DELETED, INACCESSIBLE, OR DISCLOSED DATA.
											</p>
											<p style={styleRule2}>
												6.4 Disclaimer of Warranties. TO THE MAXIMUM EXTENT PERMITTED BY LAW, Gorilla Bot DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESSED OR IMPLIED, IN EQUITY OR AT LAW, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Gorilla Bot DOES NOT WARRANT THAT THE SERVICES WILL MEET YOUR REQUIREMENTS OR NEEDS. YOU SHALL BEAR THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE SERVICES.
											</p>
											<p style={styleRule2}>
												6.5 Limitation. Some jurisdictions do not allow or limit the exclusion of warranties. In such jurisdiction, these provisions shall apply to you to the maximum extent allowed by law.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Limitation of Liability"}>Limitation of Liability</a>
											</h4>
											<p style={styleRule2}>7.1 Special Cases. Nothing here in shall exclude or limit the liability of either party for death or personal injury resulting from the negligence of that party or its directors, officers, employees, contractors or agents, or in respect of fraud or of any statements made fraudulently by either party. Nothing herein shall exclude or limit the liability of either party for damages arising out of the former party’s gross negligence or willful misconduct. Gorilla Bot agrees to indemnify you against any liability resulting from the infringement of third-party intellectual property rights by the software or other content provided by Gorilla Bot as part of the Services. </p>
											<p style={styleRule2}>
											7.2 Responsibility.SUBJECT TO 7.1, YOU ARE ULTIMATELY RESPONSIBLE FOR ANY LOSSES OR DAMAGES INCURRED BY YOU AS A RESULT OF USING OR INSTALLING THE SERVICES. Gorilla Bot WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF YOUR USE OF OR INABILITY TO USE THE SERVICES, EVEN IF Gorilla Bot HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF THE DAMAGE WAS FORESEEABLE. 
											</p>
											<p style={styleRule2}>
											7.3 Limitation on Liability. EXCEPT AS STATED IN SECTION 7.1, Gorilla Bot AND ITS AFFILIATES, OFFICERS, LICENSORS, AND/OR CONTRACTORS SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT THAT A STATE DOES NOT PERMIT THE EXCLUSION OR LIMITATION OF LIABILITY AS SET FORTH HEREIN, Gorilla Bot’S LIABILITY IS LIMITED TO THE FULL EXTENT PERMITTED BY LAW IN SUCH STATE. 
											</p>
											<p style={styleRule2}>
											7.4 Data Transfer ALL MATERIAL AND/OR DATA DOWNLOADED OR OBTAINED THROUGH THE SERVICES OR RELATED SERVICES IS AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR THE USE OR YOUR POSSESSION OF SUCH DATA OR MATERIAL Gorilla Bot DOES NOT ACTIVELY MONITOR ANY INFORMATION OR MATERIAL TRANSFERRED THROUGH ITS SERVICES AND CANNOT WARRANT THE CONTENT OF SUCH MATERIAL OR DATA. 
											</p>
											<p style={styleRule2}>7.5 Limitations on Remedy Except for actions and claims related to a party’s indemnification obligations, all actions or claims relating to this Agreement must be brought within one (1) year from the date when the cause of action occurred.'</p>	
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Termination"}>Termination</a>
											</h4>
											<p style={styleRule2}>8.1 Term This Agreement is effective until terminated by you or by Gorilla Bot. Any Additional Services is licensed only for the Subscription Period selected during the registration or upgrade, which is generally a one-year term. The Subscription Period may be renewed by paying an additional license fee as set forth on the GORILLA BOT website. This renewal fee may be charged automatically to the credit card used to initially pay for the Services. </p>
											<p style={styleRule2}>
											8.2 Termination by You. Services may be terminated by notifying Gorilla Bot of your intent to terminate this Agreement. Notification of termination must be sent by email to support@biggorillaapps.com. Your termination will be effective upon Gorilla Bot’s receipt and processing of the email. Processing may take up to 24 hours. 
											</p>
											<p style={styleRule2}>
											8.3 Termination by Gorilla Bot. Gorilla Bot may terminate this Agreement at any time and for any reason. Gorilla Bot may monitor its systems for excessive consumption of network resources and may take technical or other remedies deemed necessary to prevent or eliminate any excessive consumption. If Gorilla Bot deems your use to be excessive, Gorilla Bot may terminate your account or adjust the price of the Services.
											</p>
											<p style={styleRule2}>
											8.4 Events Upon Termination. Upon termination, you must immediately cease using the Services. Upon termination, Gorilla Bot may disable further use of the Services or related Services without further notice and may delete, remove, and erase any account information and any data stored by Gorilla Bot. Such deletions are in Gorilla Bot’s sole discretion and may occur without notice to you. No refunds shall be given for any reason except in the event that Gorilla Bot terminates without cause or you terminate due to a material breach by Gorilla Bot.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Branding and Attribution"}>Branding and Attribution</a>
											</h4>
											<p style={styleRule2}>
											9.1 Attribution You agree to display any attribution(s) required by biggorillaapps.com as described in any documentation for the Services. Without limiting the foregoing, users of the Basic Services must display "Powered by GORILLA BOT" and a link to GORILLA BOT on any Device or Application connected to the Services. Gorilla Bot grants to you a limited, freely revocable, nontransferable, nonsublicenseable, nonexclusive license during the term of your subscription to display GORILLA BOT Marks solely for the purpose of promoting or advertising that your use the Services. You must only use the GORILLA BOT Marks in accordance with these Terms. You understand and agree that Gorilla Bot has the sole discretion to determine whether your attribution(s) and use of GORILLA BOT Marks is in accordance with the above requirements and any applicable guidelines. 
											</p>
											<p style={styleRule2}>
											9.2 Promotional and Marketing Use In the course of promoting, marketing, or demonstrating the Services you are using, Gorilla Bot may produce and distribute incidental depictions, including screenshots, video, or other content from your Application or Device, and may use your company or product name and logos. You hereby grant Gorilla Bot all necessary rights for these purposes, provided that Gorilla Bot will promptly remove the foregoing content from all marketing materials upon your request.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Indemnity"}>Indemnity</a>
											</h4>
											<p style={styleRule2}>
											You agree to release, indemnify, defend and hold harmless Gorilla Bot and any of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorney's fees and expenses, of third parties relating to or arising out of (a) your misuse of the Services, (b) your breach of this Agreement, or (c) your infringement upon any intellectual property or other proprietary right of any person or entity, resulting from your use of the Services and not from the Services themselves. Gorilla Bot may, at its own expense, assume the defense and control of any matter otherwise subject to indemnification by you. Doing so shall not excuse your indemnity obligations in this Agreement. The terms of this paragraph will survive any termination or cancellation of the Agreement.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Privacy"}>Privacy</a>
											</h4>
											<p style={styleRule2}>
											11.1 Privacy Policy Gorilla Bot has built its products and services with your privacy and security in mind. To keep you informed of its privacy practices, Gorilla Bot periodically publishes a Privacy Policy that is incorporated by reference into this Agreement. You can view the Privacy Policy. Gorilla Bot may update its Privacy Policy in its sole discretion. Any amendments to the Privacy Policy will be posted on the Gorilla Bot website. Please periodically review our website for changes to the Privacy Policy.
											</p>
											<p style={styleRule2}>
											11.2 Information Collection. Gorilla Bot may gather anonymized information related to the use of its Services. Gorilla Bot may use this anonymized information to provide the services and share this information with others, improve its products, track geographical data, or enforce the terms of this Agreement. Gorilla Bot may disclose the collected information if required or permitted by law or in response to a subpoena or other legal process. In order to promote awareness, detection and prevention of Internet security risks, Gorilla Bot may share certain anonymized information with research organizations and other vendors. 
											</p>
											<p style={styleRule2}>
											11.3 Opt-Out You agree that Gorilla Bot may communicate with you via email and any similar technology for any purpose relating to the Services. Gorilla Bot occasionally sends out informational emails about its products and services. You may ‘opt-out’ of receiving information not directly related to the Services you have installed or are using by emailing us. If you do not opt out then your acceptance of this Agreement will constitute your affirmative consent to receiving marketing and promotional material from Gorilla Bot and its affiliates. Features of the Services that gather personal information can be disabled at any time by the user through the Services menu. Please refer to documentation accompanying the Services for assistance in doing so.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Severability"}>Severability</a>
											</h4>
											<p style={styleRule2}>
											If a provision of the Agreement is held by a court of competent jurisdiction to be invalid, void, or unenforceable, the remaining provisions of the Agreement will not be affected, impaired or invalidated. If the absence of the provision adversely affects the substantive rights of a party, the parties agree to replace the provision with a new provision that closely approximates the economic and proprietary results intended by the parties.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Force Majeure"}>Force Majeure</a>
											</h4>
											<p style={styleRule2}>
											Any delays in or failure by either party in the performance of any obligation under this Agreement shall be excused to the extent that such failure or delay is caused by occurrences beyond the party's reasonable control, including acts of God, storms, hurricane, earthquakes, riots, war (whether declared or not), sabotage, interruption or failure of telecommunication or digital transmission links, Internet slow-downs or failures, and any other cause that cannot reasonably be foreseen or controlled by such party. A party will not be liable as result of failures or errors related to the use or operation of the Internet.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Entire Agreement"}>Entire Agreement</a>
											</h4>
											<p style={styleRule2}>
											The Agreement, the Privacy Policy, and the attached Schedules contain the entire and exclusive Agreement and understanding between the parties on the subject matter of the Agreement. The Agreement supersedes all prior agreements, understandings and arrangements related to the subject matter. No representation, undertaking or promise made prior to the Agreement shall be effective or valid except as may be expressly stated in the Agreement.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Waiver"}>Waiver</a>
											</h4>
											<p style={styleRule2}>
											No waiver, delay or discharge by a party will be valid unless in writing and signed by an authorized representative of the party against which its enforcement is sought. Neither the failure of either party to exercise any right of termination nor the waiver of any default will constitute a waiver of the rights granted in the Agreement with respect to any subsequent or other default.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Amendments"}>Amendments</a>
											</h4>
											<p style={styleRule2}>
											Gorilla Bot may amend this Agreement and the Services and related services offered under the Agreement in its sole discretion by posting such changes at GORILLA BOT web-site, including license fees, availability, equipment and Services requirements, and limits or restrictions on the use of Services or services. Gorilla Bot may impose additional reasonable restraints on the use of the Services at any time. Any amendment made to this Agreement shall be posted on the GORILLA BOT website and is effective immediately after posting the Agreement. The website posting shall be your sole notice of any such changes. You agree to check the GORILLA BOT website periodically to obtain notice of any changes. Continued use of the Services after a change constitutes your acceptance of the change. Section headings are for convenience only and are not part of the Agreement itself. Notwithstanding anything to the contrary contained in this paragraph, no change to this Agreement shall materially increase your fees or legal protections under the Agreement unless you are given at least 30 days’ advance written notice of such change.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Assignment"}>Assignment</a>
											</h4>
											<p style={styleRule2}>
											You may not assign or transfer, or purport to assign or transfer, any of your rights, duties, or obligations under the Agreement to any person or entity, in whole or in part, whether by assignment, merger, transfer of assets, sale of stock, operation of law, or otherwise. Gorilla Bot may assign or transfer this Agreement in its sole discretion.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Notices"}>Notices</a>
											</h4>
											<p style={styleRule2}>
											All questions, notices, demands, or requests to Gorilla Bot with respect to this Agreement shall be made in writing to: biggorillaapps.com., 86-90 Paul Street, London, EC2A 4NE. All notices to you shall be made by posting the notice on the GORILLA BOT website. 
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Survival"}>Survival</a>
											</h4>
											<p style={styleRule2}>
											This Agreement shall be applicable for as long as you use the Services. All provisions regarding confidentiality, proprietary rights, limitation of liability, indemnity, and non-disclosure shall survive this Agreement.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Arbitration"}>Arbitration</a>
											</h4>
											<p style={styleRule2}>
											To the extent permitted by law, before you may begin arbitration with respect to a dispute involving any aspect of this Agreement, you shall notify Gorilla Bot, and any other party to the dispute for the purpose of seeking dispute resolution. If the dispute is not resolved within sixty (60) days after the initial notice, then a party may proceed in accordance with the following: 
											</p>
											<p style={styleRule2}>
											20.1 Any unresolved dispute arising under the terms of this Agreement shall be decided by arbitration conducted through the services of the American Arbitration Association (hereinafter referred to as the “AAA”). 
											</p>
											<p style={styleRule2}>
											20.2 Notice of demand for an arbitration hearing shall be in writing and properly served upon the parties to this Agreement. Arbitration hearings shall be held in the state of California at a location mutually agreeable to the parties.
											</p>
											<p style={styleRule2}>
											20.3 There shall be one Arbitrator to hear the matter. The parties shall initially agree to a panel of three possible Arbitrators to hear the matter and each party shall have the opportunity to name one Arbitrator to be dropped from the panel until one remains. The party giving notice of the Arbitration demand shall be first to indicate its selection.
											</p>
											<p style={styleRule2}>
											20.4 All costs of the Arbitration and the AAA shall be borne equally by both parties to this agreement, regardless of the final decision. The defaulting party as determined by the Arbitrator, shall pay all other costs and expenses, including reasonable attorney’s fees, incurred by the party in enforcing its rights under this Agreement. 
											</p>
											<p style={styleRule2}>
											20.5 You affirm that you are older than 18 years of age, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in this agreement, and to abide by and comply with this agreement.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Our clients"}>Our clients</a>
											</h4>
											<p style={styleRule2}>
											By registering on Gorilla Bot with your company email address and activate it upon registration, we expect you are using Gorilla Bot for company purpose and we can use company name (Extracted from Email Address) as our clients; provided that Gorilla Bot will promptly remove your company name from all marketing materials upon your request.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Acceptance"}>Acceptance</a>
											</h4>
											<p style={styleRule2}>
											BY USING, APPLYING FOR, OR ACCEPTING THE SERVICES YOU AGREE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT AND THAT YOU WILL BE BOUND BY AND COMPLY WITH IT. DO NOT USE THE SERVICES IF YOU DO NOT AGREE TO THIS AGREEMENT.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Fair Usage Policy"}>Fair Usage Policy</a>
											</h4>
											<p style={styleRule2}>
											Gorilla Bot loads website widget as an iframe on your website so the amount of traffic on your website effects on our server performance. We therefore have a fair usage policy of 25 concurrent connections from your website. Means that at any particular point in time only 25 concurrent users can access your chatbot on your website. If you need more concurrent users accessing the chatbot, you can contact team@Gorilla Bot.com with your requirements.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<div className="service-text">
											<h4 className="title" style={styleRule6}>
												<a href={ROUTES_LINKS.terms_conditions + "#Refund Policy"}>Refund Policy</a>
											</h4>
											<p style={styleRule2}>
											No refunds shall be offered in case of forgetting to remove payment information and forgetting to unsubscribe.
											</p>
										</div>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
							</div>
							{/* .row */}
						</div>
					</div>
					{/* .container */}
				</section>
				{/* .section */}
				<footer className="footer bg-lighter" id="footer">
					<div className="container">
						<div className="row g-3 align-items-center justify-content-md-between py-4 py-md-5">
							<div className="col-md-3">
								<div className="footer-logo">
									<Link to={ROUTES_LINKS.home} className="logo-link">
										<img className="logo-light logo-img" src="/assets/images/logo.png" srcSet="/assets/images/logo2x.png 2x" alt="logo" />
										<img className="logo-dark logo-img" src="/assets/images/logo-dark.png" srcSet="/assets/images/logo-dark2x.png 2x" alt="logo-dark" />
									</Link>
								</div>
								{/* .footer-logo */}
							</div>
							{/* .col */}
							<div className="col-md-9 d-flex justify-content-md-end">
								<ul className="link-inline gx-4">
									<li><Link to={ROUTES_LINKS.terms_conditions}>Terms and Conditions</Link></li>
									<li><Link to={ROUTES_LINKS.private_policy}>Privacy Policy</Link></li>
								</ul>
								{/* .footer-nav */}
							</div>
							{/* .col */}
						</div>
						<hr className="hr border-light mb-0 mt-n1" />
						<div className="row g-3 align-items-center justify-content-md-between py-4">
							<div className="col-md-8">
								<div className="text-base">Copyright © 2023 Gorilla Metrics.</div>
							</div>
							{/* .col */}
							<div className="col-md-4 d-flex justify-content-md-end">
								<ul className="social">
									<li><a href="javascript:void(0)"><em className="icon ni ni-twitter" /></a></li>
									<li><a href="javascript:void(0)"><em className="icon ni ni-facebook-f" /></a></li>
									<li><a href="javascript:void(0)"><em className="icon ni ni-instagram" /></a></li>
									<li><a href="javascript:void(0)"><em className="icon ni ni-pinterest" /></a></li>
								</ul>
								{/* .footer-icon */}
							</div>
							{/* .col */}
						</div>
						{/* .row */}
					</div>
					{/* .container */}
				</footer>
				{/* .footer */}
			</div>
			{/* main @e */}
			{
				isLoading && <div className="position-fixed top-0 w-100 h-100 bg-dark d-flex justify-center align-center flex-column" style={{ zIndex: 1000 }}>
					{/* <img src="/assets/images/logo-dark.png" alt="loading state" className="img-fluid" width="10%" /> */}
					<Components.Spinner color="primary" />
				</div>
			}
		</div>

	);
}