// Terms and Conditions
import "./index.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Components from "../../../components";
import ROUTES_LINKS from "../../../constants/routes";

export default function TermsAndConditions() {
	const [isLoading, setIsLoading] = useState(true);
	const styleRule1 = { marginRight: '0cm', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt' };
	const styleRule2 = { fontSize: 14, fontFamily: '"Ubuntu",sans-serif', color: '#8E9BAF' };
	const styleRule3 = { fontSize: 14, fontFamily: 'Symbol', color: '#8593A9' };
	const styleRule4 = { marginTop: '0cm', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '0cm', fontSize: 17, fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal' };
	const styleRule5 = { fontSize: 14, fontFamily: '"Ubuntu",sans-serif', color: '#8593A9' };
	const styleRule6 = { fontFamily: '"Ubuntu",sans-serif', color: '#e14954' };

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
			console.log("is loading", isLoading);
		}, 2000);
	});


	return (

		<div className="nk-app-root">
			<Helmet>
				<title>Privacy Policy</title>
				{/* <!-- Landing Page StyleSheet --> */}
				<link rel="stylesheet" href="/assets/css/landingPage/dashlite.css?ver=3.2.3" />
				<link id="skin-default" rel="stylesheet" href="/assets/css/landingPage/theme.css?ver=3.2.3" />
				<link rel="stylesheet" href="/assets/css/skins/theme-red.css" />
			</Helmet>

			{/* main @s */}
			<div className="nk-main">
				<section className="section section-service pb-0" id="service">
					<div className="container">
						<div className="row justify-content-center text-center">
							<div className="col-12">
								<div className="section-head">
									<h2 className="title">Privacy Policy</h2>
									<p style={styleRule1}>This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.</p>
									<p style={styleRule1}>Our company is committed to protecting your privacy and providing a safe and secure experience for our users. This privacy policy outlines the collection, use, and sharing of the data and information collected through our chatbot for Facebook. By using our chatbot, you agree to the terms and conditions mentioned in this policy.</p>
								</div>
								{/* .section-head */}
							</div>
							{/* .col */}
						</div>
						{/* .row */}
						<div className="section-content">
							<div className="row justify-content-start g-gs">
								{/* col */}
								<div className="col-12">
									{/* terms and condiction */}
									<div className="service">
										<h2 style={styleRule4}><strong><span style={styleRule6}>1. Collection of Information</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>Our chatbot collects the information which is necessary to provide you with the services and features of our chatbot. This information may include your Facebook ID, name, profile picture, and other contact information if authorized by you. The chatbot also stores the messages exchanged between you and the chatbot for the purpose of understanding your needs and preferences.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>2. Use of Information</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>The information collected by our chatbot is used solely for the purpose of providing you with the best possible experience. The information is used to personalize your chatbot experience and to improve our chatbot's services. We may also use the information to communicate with you about updates, new features, and promotions related to our chatbot.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>3. Protection of Information</span></strong></h2>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; We do not use vulnerability scanning and/or scanning to PCI standards.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; We only provide articles and information.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; We never ask for personal or private information like email addresses or credit card numbers.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; We use regular Malware Scanning.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; All transactions are processed through a gateway provider and are not stored or processed on our servers.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>4. Sharing of Information</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>We do not share, sell, or rent your personal information with any third party without your consent, except in the following situations:</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; To facilitate communication between you and the chatbot.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; To comply with legal obligations, such as responding to a court order or government request.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; In case of a merger, acquisition, or sale of our company, your information may be transferred to the new owner.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>5. User Control over Information</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>Our chatbot allows you to control the information collected and shared through the settings in the Facebook platform. You can choose to revoke our chatbot's access to your information at any time by changing your Facebook settings.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>6. Cookie Policy</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>Our chatbot uses cookies to track user activity and personalize your chatbot experience. Cookies are small files that are stored on your device and help us understand your preferences and improve our services. You can choose to disable cookies or delete them from your device at any time, but this may limit the functionality of our chatbot. you can manage your cookie preferences through your browser settings. Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</span></p>
										<p style={styleRule1}><span style={styleRule2}>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.</span></p>
										<p style={styleRule1}><span style={styleRule2}> If you disable cookies off, some features will be disabled It won't affect the user's experience that make your site experience more efficient and some of our services will not function properly.</span></p>
										<p style={styleRule1}><span style={styleRule2}>However, you can still place orders.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>7. Third-party disclosure</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</span></p>
										<p style={styleRule1}><span style={styleRule2}>However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>8. Third-Party Links and Services</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>Our app may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices of these third-party websites or services, so we encourage you to read their privacy policies before using them.</span></p>
										<p style={styleRule1}><span style={styleRule2}>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; Once this privacy policy is created, we will add a link to it on our home page or as a minimum on the first significant page after entering our website.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; Our Privacy Policy link includes the word 'Privacy' and can be easily be found on the page specified above.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; Users will be notified of any privacy policy changes</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>9. Security</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>We take reasonable precautions to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee its absolute security.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>10. Children's Privacy</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>We do not knowingly collect or solicit personal information from children under the age of 13. If you are under 13, please do not use our app or provide any personal information. If you believe a child under 13 has provided us with personal information, please contact us and we will delete it.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>11. Data Retention</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>We will retain your information for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law. Application is fully GDPR Complaint and clearly lists the way we use your data. Please refer to the following link for our Data Protection Addendum.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>12. How does our site handle do not track signals?</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>13. COPPA (Children Online Privacy Protection Act)</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.</span></p>
										<p style={styleRule1}><span style={styleRule2}>We do not specifically market to children under 13.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>14. Fair Information Practices</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</span></p>
										<p style={styleRule1}><span style={styleRule2}>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; We will notify the users via email within 30 business days.</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; We also agree to the Individual Redress Principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>15. CAN SPAM Act</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</span></p>
										<p style={styleRule1}><span style={styleRule2}>We collect your email address in order to:</span></p>
										<p style={styleRule1}><span style={styleRule2}>To be in accordance with CANSPAM we agree to the following:</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; If at any time you would like to unsubscribe from receiving future emails, you can email us at and we will promptly remove you from ALL correspondence.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>16. How to delete your data?</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>You can remove your complete data by going into account settings and then click on "Delete Complete Information" Request.  Once you request your complete profile information along with the data stored on the servers will be deleted and we will send you the confirmation email.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>17. Your Rights</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>You have the right to access, correct, update, or delete the personal information we hold about you. You also have the right to object to our processing of your information, restrict our use of your information, and request that we transfer your information to another party. To exercise any of these rights, please contact us using the information below. Users are able to change their personal information:</span></p>
										<p style={styleRule1}><span style={styleRule3}>·</span><span style={styleRule5}>&nbsp; By chatting with us or sending us a ticket</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>18. Updates to the Privacy Policy</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>We reserve the right to update or modify this privacy policy at any time. Any changes will be notified to you through the chatbot or by email. It is your responsibility to review this policy regularly and to understand the changes made.</span></p>
										<h2 style={styleRule4}><strong><span style={styleRule6}>19. Contact Us</span></strong></h2>
										<p style={styleRule1}><span style={styleRule2}>If you have any questions or concerns about our chatbot's privacy policy, please contact us at below address. We will do our best to address your queries and concerns in a timely manner.</span></p>
									</div>
									{/* terms and condition end */}
								</div>
								{/* .col */}
								<div className="col-12">
									<ul style={styleRule1}>
										<li style={styleRule2}>
											<a href="https://biggorillaapps.com/">Big Gorilla Apps</a>
										</li>
										<li style={styleRule5}>• 86-90 Paul Street</li>
										<li style={styleRule5}>• London</li>
										<li style={styleRule5}>• EC2A 4NE</li>
									</ul>
								</div>
							</div>
							{/* .row */}
						</div>
					</div>
					{/* .container */}
				</section>
				{/* .section */}
				<footer className="footer bg-lighter" id="footer">
					<div className="container">
						<div className="row g-3 align-items-center justify-content-md-between py-4 py-md-5">
							<div className="col-md-3">
								<div className="footer-logo">
									<Link to={ROUTES_LINKS.home} className="logo-link">
										<img className="logo-light logo-img" src="/assets/images/logo.png" srcSet="/assets/images/logo2x.png 2x" alt="logo" />
										<img className="logo-dark logo-img" src="/assets/images/logo-dark.png" srcSet="/assets/images/logo-dark2x.png 2x" alt="logo-dark" />
									</Link>
								</div>
								{/* .footer-logo */}
							</div>
							{/* .col */}
							<div className="col-md-9 d-flex justify-content-md-end">
								<ul className="link-inline gx-4">
									<li><Link to={ROUTES_LINKS.terms_conditions}>Terms and Conditions</Link></li>
									<li><Link to={ROUTES_LINKS.private_policy}>Privacy Policy</Link></li>
								</ul>
								{/* .footer-nav */}
							</div>
							{/* .col */}
						</div>
						<hr className="hr border-light mb-0 mt-n1" />
						<div className="row g-3 align-items-center justify-content-md-between py-4">
							<div className="col-md-8">
								<div className="text-base">Copyright © 2023 Gorilla Metrics.</div>
							</div>
							{/* .col */}
							<div className="col-md-4 d-flex justify-content-md-end">
								<ul className="social">
									<li><a href="javascript:void(0)"><em className="icon ni ni-twitter" /></a></li>
									<li><a href="javascript:void(0)"><em className="icon ni ni-facebook-f" /></a></li>
									<li><a href="javascript:void(0)"><em className="icon ni ni-instagram" /></a></li>
									<li><a href="javascript:void(0)"><em className="icon ni ni-pinterest" /></a></li>
								</ul>
								{/* .footer-icon */}
							</div>
							{/* .col */}
						</div>
						{/* .row */}
					</div>
					{/* .container */}
				</footer>
				{/* .footer */}
			</div>
			{/* main @e */}
			{
				isLoading && <div className="position-fixed top-0 w-100 h-100 bg-dark d-flex justify-center align-center flex-column" style={{ zIndex: 1000 }}>
					{/* <img src="/assets/images/logo-dark.png" alt="loading state" className="img-fluid" width="10%" /> */}
					<Components.Spinner color="primary" />
				</div>
			}
		</div>

	);
}